import { useEffect } from "react";

function Chat({ user }) {
  const scriptSource = `(function(w, d, x, id){
        s=d.createElement('script');
        s.src='https://d10hxo0w83tp48.cloudfront.net/amazon-connect-chat-interface-client.js';
    s.async=1;
    s.id=id;
    d.getElementsByTagName('head')[0].appendChild(s);
    w[x] = w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
})(window, document, 'amazon_connect', '35f6d5db-aa1c-4cf0-8e35-c7d35f302ffb');
amazon_connect('customerDisplayName',function(callback){callback("${user.given_name}")})
amazon_connect('styles', { iconType: 'CHAT', openChat: { color: '#ffffff', backgroundColor: '#123456' }, closeChat: { color: '#ffffff', backgroundColor: '#123456'} });
amazon_connect('snippetId', 'QVFJREFIaTU5UjFrcTVBNTNKSXR2cWhFSlFmRWNjejVoazlZVi91emZLcHA0b1JtbEFIRzNmaklESDlIMERObDRxZWRNNnl4QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNMGoxTXlaR2JKQzcxV25TL0FnRVFnQ3ZNRXZreDFBYXlQaGc4TTN0OTZYSG1wN0wrYmp1dUJsVnQxUm1LOXM2SHY1aXo0TUwrZjlrQk1jQ0Y6Omh2YTFEUGJtalhBQUh4UHhZNnlpSXA0ZjZiMzA5UFV2Q3FNTDhZK0hrcXV0WGZ5TnQwT1RuOE5kS3VVZjNUZVpPOHRHcGQ2djk5S0J1bnd0VzBiWGI5NXNiVTdJM2pjejNWUTB3VGZ1Y2xibi9Md09aR0Y4dHJDdXJ3L0prTUxCU2dlOXM2UnBiNEUzbXBUZUlaSTE0SHU0UE9lYWZvYz0=');
amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown', 'application/vnd.amazonaws.connect.message.interactive', 'application/vnd.amazonaws.connect.message.interactive.response' ]);
amazon_connect('contactAttributes',{email: '${user.email}'})
amazon_connect('customLaunchBehavior', {
    skipIconButtonAndAutoLaunch: true,
    alwaysHideWidgetButton: true,
    programmaticLaunch: (function(launchCallback) {
        var launchWidgetBtn = document.getElementById('launch-widget-btn');
        if (launchWidgetBtn) {
            launchWidgetBtn.addEventListener('click', launchCallback);
            window.onunload = function() {
            launchWidgetBtn.removeEventListener('click', launchCallback);
            return;
            }
        }
    })
});
`;

  useEffect(() => {
    const scriptExists = document.getElementById("chatScriptID");
    if (!scriptExists) {
      const script = document.createElement("script");
      script.id = "chatScriptID";
      script.type = "text/javascript";
      script.async = true;
      script.textContent = scriptSource;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return <></>;
}

export default Chat;
