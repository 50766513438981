import React, { useState, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import {
  getCurrentUser,
  signOut,
  signInWithRedirect,
  fetchUserAttributes,
} from "aws-amplify/auth";
import awsconfig from "./aws-exports";
import Chat from "./Components/Chat/Chat";

awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;
Amplify.configure(awsconfig);

function App() {
  const hour = new Date().getHours();
  const greeting =
    "Good " + (hour < 12 ? "Morning" : hour < 18 ? "Afternoon" : "Evening");

  // Amazon Federate Midway athentication
  const [user, setUser] = useState(null);
  // getUser function
  async function getUser() {
    try {
      // const userData = await getCurrentUser();
      const userData = await fetchUserAttributes();
      // email: 'lohsemar@amazon.com', email_verified: 'false', family_name: 'Lohse-Miranda', given_name: 'Mark [C]'
      return userData;
    } catch {
      return console.log("Not signed in");
    }
  }

  // Use effect for auth
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log(event);
      switch (event) {
        case "signedIn":
          getUser().then((userData) => {
            if (userData) {
              if (userData.given_name.endsWith("[C]")) {
                const noC = userData.given_name.slice(0,-3)
                userData.given_name=noC
              }
              setUser({
                email: userData.email,
                family_name: userData.family_name,
                given_name: userData.given_name,
              });
            }
          });
          break;
        case "signedOut":
          setUser(null);
          break;
        case "signIn_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });
    getUser().then((userData) => {
      if (userData) {
        if (userData.given_name.endsWith("[C]")) {
          const noC = userData.given_name.slice(0,-3)
          userData.given_name=noC
        }
        setUser({
          email: userData.email,
          family_name: userData.family_name,
          given_name: userData.given_name,
        });
      }
    });
  }, []);
  return (
    <div className="flex justify-center text-center mt-4">
      {user ? (
        <div>
          <div>
            <button
              className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              onClick={() => signOut()}
            >
              Logout
            </button>
            <Chat user={user} />
          </div>
          <div className="fixed bottom-4 inset-x-0">
            <div className="text-center mb-4">
              <p>
                {greeting} {user?.given_name}
              </p>
            </div>
            <div className="">
              <button
                id="launch-widget-btn"
                className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Start Chat
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <button
            className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
            onClick={() =>
              signInWithRedirect({ customProvider: "FederateOIDC" })
            }
          >
            Login
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
